<template>
  <div></div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  props: {
    isLogin: {
      type: Boolean
    }
  },
  setup (props) {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const useRoute = inject('useRoute')
    const state = reactive({
    })
    const onRedirect = () => {
      const redirect = Cookies.get('redirect')
      const loginUrl = Cookies.get('loginUrl') ?? ''
      const loginParam = Cookies.get('loginParam') ?? ''
      const loginRoleId = Cookies.get('loginRoleId') ?? ''
      const token = Cookies.get('token') ?? ''
      Cookies.remove('redirect')
      Cookies.remove('loginUrl')
      Cookies.remove('loginParam')
      Cookies.remove('loginRoleId')
      if (loginUrl !== '' && token === '') {
        post(loginUrl, {
          ...JSON.parse(loginParam),
          weixinOpenid: Cookies.get('weixinOpenid'),
          type: 'WEIXIN'
        }).then(res => {
          console.log(res)
          if (res.code === 0) {
            Cookies.set(loginRoleId, res.data.loginId, { expires: 1 })
            Cookies.set('accountId', res.data.accountId, { expires: 1 })
            Cookies.set('token', res.data.token, { expires: 1 })
            useRouter.push(redirect)
          } else {
            useRouter.push(redirect)
          }
        })
      } else {
        useRouter.push(redirect)
      }
    }
    const init = () => {
      if (typeof useRoute.query.openid === 'undefined') {
        Cookies.set('redirect', useRoute.query.redirect, { expires: 1 })
        if ((typeof useRoute.query.loginUrl !== 'undefined')) Cookies.set('loginUrl', useRoute.query.loginUrl, { expires: 1 })
        if ((typeof useRoute.query.loginParam !== 'undefined')) Cookies.set('loginParam', useRoute.query.loginParam, { expires: 1 })
        if ((typeof useRoute.query.loginRoleId !== 'undefined')) Cookies.set('loginRoleId', useRoute.query.loginRoleId, { expires: 1 })
        location.href = 'https://open.feddon.com/api/test/weixin.weixinOauth' + '?targetUrl=' + encodeURIComponent(window.location.protocol + '//' + window.location.host + location.pathname)
      } else {
        if (typeof Cookies.get('redirect') === 'undefined') {
          useRouter.push({ path: '/' })
        } else {
          Cookies.set('weixinUnionid', useRoute.query.unionid, { expires: 1 })
          Cookies.set('weixinOpenid', useRoute.query.openid, { expires: 1 })
          Cookies.set('weixinNickname', useRoute.query.nickname, { expires: 1 })
          Cookies.set('weixinHeadimgurl', useRoute.query.headimgurl, { expires: 1 })
          onRedirect()
        }
      }
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
